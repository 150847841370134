$yellow: #ffec89;
$blue: #37489c;

@font-face {
  font-family: 'druk-wide-italic';
  src: url('https://d39x71nefpqfvh.cloudfront.net/fonts/DrukWide-BoldItalic.otf');
}

@font-face {
  font-family: 'druk-wide-medium-italic';
  src: url('https://d39x71nefpqfvh.cloudfront.net/fonts/DrukTextWide-MediumItalic.otf');
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: black;
  background-image: url('https://d39x71nefpqfvh.cloudfront.net/images/rhcp-dream-canteen_locator-bg-2.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

@media (max-width: 800px) {
  .wrapper {
    background-image: url('https://d39x71nefpqfvh.cloudfront.net/images/rhcp-dream-canteen_locator-bg-mobile-2.jpg');
  } 
}

.videoBg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.wrapperOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
}

.content {
  position: relative;
  max-width: 1000px;
}
@media (max-width: 800px) {
  .content {
    max-width: 95%;
  }
}


.loadingCopy {
  font-size: 1.1em;
}
@media (max-width: 800px) {
  .loadingCopy {
    font-size: 1em;
  }
}


.emailFormWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0,0,0,0.8);
}

.emailForm {
  max-width: 500px;
}

.enterWithEmail {
  margin: 20px auto;
}
@media (max-height: 560px) {
  .enterWithEmail {
    margin: 5px auto;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 690px;
  margin: 0 auto;

  p {
    margin-bottom: 8px;
    width: 100%;
  }

  button {
    margin-top: 20px;
    font-size: 1.5em;
  }
}

.loadingHr {
  width: 240px;
}

.loadingLogo {
  height: 130px;
  margin-bottom: 18px;
}

.welcomeTitle {
  font-family: 'druk-wide-italic', sans-serif;
  color: $yellow;
  font-size: 3.5em;
  font-weight: 400;
  margin: 20px auto;
  text-shadow: $blue -2px 8px 0;
  -webkit-text-stroke: 2px $blue;
  text-stroke: 2px $blue;
}
@media (max-width: 800px) {
  .welcomeTitle {
    margin: 10px auto;
    font-size: 1.9em;
  }
}

.welcomeTitleImg {
  max-width: 680px;
  display: block;
  margin: auto;
}
// .welcomeTitleImgMobile {
//   display: none;
// }
@media (max-width: 680px) {
  .welcomeTitleImg {
    max-width: 100%;
  }
}
@media (max-height: 500px) {
  .welcomeTitleImg {
    max-width: 350px;
  }
}
// @media (max-width: 600px) {
//   .welcomeTitleImg {
//     display: none;
//   }
//   .welcomeTitleImgMobile {
//     display: block;
//     max-width: 100%;
//   }
// }

.welcomeHeader {
  font-family: 'druk-wide-medium-italic', sans-serif;
  margin: 0;
  font-size: 2.2em;
  text-shadow: $blue -2px 4px 0;
  -webkit-text-stroke: 0.0009em $blue;
  text-stroke: 0.0009em $blue;
}

@media (max-width: 800px) {
  .welcomeHeader {
    font-size: 1.8em;
  }
}

@media (max-height: 680px) {
  .loadingLogo {
    height: 100px;
  }
  .welcomeTitle {
    font-size: 3em;
    margin: 10px auto;
  }

  .loading {
    button {
      font-size: 0.9em;
      padding: 12px;
      margin-top: 6px;
    }
    p {
      font-size: 1em;
    }
  }
}

@media (max-height: 560px) {
  .loadingLogo {
    height: 80px;
  }

  .welcomeHeader {
    font-size: 1.8em;
  }

  .welcomeTitle {
    font-size: 3em;
    margin: 10px auto;
  }

  .loading {
    button {
      font-size: 0.8em;
      padding: 10px;
      margin-top: 6px;
    }
    p {
      font-size: 0.9em;
    }
  }
}


.loadingBar {
  position: relative;
  margin-top: 30px;
  border: 2px solid $yellow;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  user-select: none;
}

.loadingBar::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: $yellow;
  animation: loading 4.5s ease forwards;
}

@keyframes loading {
  0% {
    width: 0%;
  }
  100% {
    width: 95%;
  }
}

@media (max-height: 560px) {
  .loadingBar {
    margin-top: 10px;
    padding: 8px;
  }
  .loadingText {
    font-size: 0.8em;
  }
}

.loadingText {
  position: relative;
  color: $blue;
}

.loadingConditional[data-status="loading"] {
  position: relative;
}
.loadingConditional[data-status="enter"] {
  display: none;
}

.enterContent {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  z-index: -999;
}

.enterContent[data-status="enter"] {
  position: relative;
  z-index: 0;
  visibility: visible;
  opacity: 1;
}

@media (max-height: 560px) {
  .enterContent {
    button {
      margin-top: 0;
      font-size: 0.8em;
    }
  }
}

.emailClose {
  cursor: pointer;
}
.emailClose:hover {
  color: $yellow;
}