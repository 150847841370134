$yellow: #ffec89;
$blue: #37489c;

@font-face {
  font-family: 'druk-wide-medium';
  src: url('/fonts/DrukTextWide-Medium.otf');
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  // text-align: center;
}

.threeWrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.webGLCanvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.webGLCanvas[data-pointer="false"] {
  pointer-events: none;
}

.cssCanvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.controls {
  position: absolute;
  bottom: 80px;
  width: 80%;
  height: 100px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wrapper[data-scene="map"] {
  .controls {
    opacity: 1;
    visibility: visible;
    z-index: auto;
    transition: opacity 800ms ease, visibility 0ms 0ms, z-index 0ms 0ms;
  }
  .controls[data-posterleft='true'] {
    opacity: 0;
    visibility: hidden;
    z-index: -10;
    transition: opacity 800ms ease, visibility 0ms 800ms, z-index 0ms 800ms;
  }
}
.wrapper[data-scene="posters"] {
  .controls {
    opacity: 0;
    visibility: hidden;
    z-index: -10;
    transition: opacity 800ms ease, visibility 0ms 800ms, z-index 0ms 800ms;
  }
} 
@media (max-width: 800px) {
  .controls {
    width: 98% !important;
  }
} 

.foreground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  pointer-events: none;
}

.albumTrigger {
  width: 200px;
  height: 300px;
  margin-left: -100px;
  // border: 2px solid red;
  cursor: pointer;
}



.mapContainer {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  // width: 40vw;
  // max-height: 80%;
  transform: translateY(-50%);
  // background-color: black;
}

// .mapContainer[data-debug='true'] {
//   border: 4px solid red;
//   margin-top: -30px;
  

//   canvas {
//     border: 4px solid green;
//   }
// }

// .mapWrapper {
//   position: relative;
//   height: 0;
//   padding-top: 100%;
//   border: 2px solid green;
// }

.map {
  position: absolute;
  top: 0;
  left: 0;
  left: 0; 
  right: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  // background-color: black;
  background-color: white;
  opacity: 1;
  overflow: hidden;
  // opacity: 0.5;

  canvas {
    width: 100%;
    height: 100%;
    background-color: white;
  }
}

.navBtn {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);

  background-color: $yellow;
  border: 1px solid $blue;
  color: $blue !important;
  font-family: 'druk-wide-medium', sans-serif;
  box-shadow: -1px 6px 0px 0px $blue;

  svg {
    fill: $blue !important;
  }
}
.navBtn[data-active="false"] {
  display: none;
}
.navBtn[data-direction="left"] {
  left: 10px;
}
.navBtn[data-direction="right"] {
  right: 10px;
}



// wall interactions
.objectOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  z-index: -10;
  // background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.4) 100%);
}
.objectOverlay[data-active="true"] {
  visibility: visible;
  opacity: 1;
  z-index: 1;
}

.objectCover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.objectBuy {
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Italiana', serif;
  background-color: transparent;
  border: 2px solid white;
  color: white;
  border-radius: 6px;
  padding: 12px 24px;
  font-size: 2em;
  max-width: 200px;
  cursor: pointer;
}

.objectClose {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 1em;
  
  text-align: center;
  cursor: pointer;
  
  background-color: $yellow;
  border: 1px solid $blue;
  color: $blue !important;
  font-family: 'druk-wide-medium', sans-serif;
  box-shadow: -2px 10px 0px 0px $blue;
  color: white;

  width: 160px;
  padding: 10px;
  box-sizing: border-box;
}
.objectCloseIcecream {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 1em;
  
  text-align: center;
  cursor: pointer;
  
  background-color: $yellow;
  border: 1px solid $blue;
  color: $blue !important;
  font-family: 'druk-wide-medium', sans-serif;
  box-shadow: -2px 10px 0px 0px $blue;
  color: white;

  width: 160px;
  padding: 10px;
  box-sizing: border-box;
}

.pinTrigger {
  margin-right: -40px;
  button {
    position: absolute;
    right: -40px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2em;
  }
}
@media (max-width: 800px) {
  .pinTrigger {
    margin-right: 0px;
    button {
      right: 0px;
    }
  }
}

.pinTriggerText {
  background-color: black;
  padding: 12px 50px 12px 0px;
  max-width: 220px;
  text-align: right;
  margin-right: 18px;
  font-size: 0.85em;
  margin-top: 10px;
}

.onlineStoreLink {
  display: none;
  visibility: hidden;
}