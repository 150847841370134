$red: #ed1c24;

$yellow: #ffec89;
$blue: #37489c;

@font-face {
  font-family: 'franklin-gothic';
  src: url('https://d39x71nefpqfvh.cloudfront.net/fonts/FRANKGO.ttf');
}

@font-face {
  font-family: 'druk-wide-medium';
  src: url('/fonts/DrukTextWide-Medium.otf');
}

#location-btn {
  position: fixed;
  top: 50%;
}

html, body, #root {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  box-sizing: border-box;
  // user-select: none;
  background-color: black;
  color: white;
  font-family: 'druk-wide-medium', sans-serif;
}



#cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: black;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-div--50 {
  width: 50%;
}

.flex-div--48 {
  width: 48%;
}

// TYPOGRAPHY
a {
  text-decoration: none;
  color: inherit;
}

p {
  line-height: 1.1em;
}

h1 {
  text-transform: uppercase;
}

.title {
  text-transform: uppercase;
}

.icon {
  fill: inherit;
  color: inherit;
  height: 1.4em;
}

.btn {
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 16px;
  font-size: 1.1em;
  font-family: 'franklin-gothic';
  text-transform: uppercase;

  .icon {
    pointer-events: none;
  }
}

.btn--circle {
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.btn--circle--sm {
  width: 50px;
  height: 50px;
}

.btn--circle--md {
  width: 60px;
  height: 60px;
}

// .btn--primary {
//   background-color: $red;
//   color: white;
// }

.btn--lg {
  padding: 16px
}

.btn--clear {
  border: 2px solid $red;
  color: $red;
  background: transparent;

  .icon {
    fill: $red;
  }
}

.btn--block {
  display: block;
  width: 100%;
}



.map-marker {
  background-image: url('./images/markers/rhcp-map-icon_black_100.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.map-marker--orange {
  background-image: url('./images/markers/rhcp-map-icon_orange_100.png');
}
.map-marker--blue {
  background-image: url('./images/markers/rhcp-map-icon_blue_100.png');
}
.map-marker--red {
  background-image: url('./images/markers/rhcp-map-icon_red_100.png');
}
.map-marker--darkblue {
  background-image: url('./images/markers/rhcp-map-icon_dark-blue_100.png');
}
.map-marker--icecream {
  background-image: url('https://d39x71nefpqfvh.cloudfront.net/images/markers/rhcp-map-icon_icecream.png');
}
.map-marker--yellow {
  background-image: url('https://d39x71nefpqfvh.cloudfront.net/images/markers/rhcp-map-icon_yellow_100.png');
}
.map-marker--gold {
  background-image: url('https://d39x71nefpqfvh.cloudfront.net/images/markers/rhcp-map-icon_gold_100.png');
}


#map-screen {
  position: relative;
}


.mapboxgl-ctrl-top-right {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 90%;
  float: auto !important;
  border-radius: 0;
}

.mapboxgl-control-container {
  width: 100%;
  height: 50px;
}

.mapboxgl-ctrl-geocoder,
.mapboxgl-ctrl {
  width: 100% !important;
  margin: 10px auto !important;
  left: 0 !important;
  right: 0 !important;
  border-radius: 0px !important;
  max-width: 100% !important;

  input {
    font-size: 1.4em;
    padding-left: 30px;
    height: 1.8em;
    border-radius: 0px !important;
  }
}
.controls-resized {
  top: 110px !important;
  width: 340px !important;  
  left: -100px !important;
  font-size: 0.9em !important;
  svg {
    top: 4px;
    // height: 1.2em;
  }
  .mapboxgl-ctrl-geocoder--button {
    top: 4px !important;
  }
}
@media (max-width: 800px) {
  .controls-resized {
    top: 250px !important;
    width: 280px !important;  
    left: -130px !important;
    font-size: 1.2em !important;

    input {
      padding-left: 40px;
    }

    svg {
      top: 6px;
      // height: 1.2em;
    }
    .mapboxgl-ctrl-geocoder--button {
      top: 0px !important;
    }
  }
}
@media (max-width: 380px) {
  .controls-resized {
    top: 270px !important;
    width: 230px !important;  
    left: -150px !important;
  }
}




.map-loading {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  padding: 0px;
  font-size: 0.8em;
  box-sizing: border-box;
  background-color: rgba(0,0,0,0.6);
  z-index: 1;
  visibility: visible;
  opacity: 1;
}
.map-loading-resized {
  bottom: 112px;
  font-size: 0.6em;
}
@media (max-width: 800px) {
  .map-loading-resized {
    top: 300px;
    bottom: auto;
  }
}
@media (max-width: 380px) {
  .map-loading-resized {
    top: 320px;
    bottom: auto;
  }
}

.map-loading[data-status='hidden'] {
  visibility: hidden !important;
  opacity: 0 !important;
}

.map-search-trigger {
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100px;
  padding: 4px;
  border-radius: 20px;;
  box-sizing: border-box;
  background-color: rgba(0,0,0,0.8);
  color: white;
  text-align: center;
  font-size: 0.8em;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.map-search-trigger.active {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.map-trigger-resized {
  top: 160px;
}
@media (max-width: 800px) {
  .map-search-trigger  {
    top: 310px !important;
  }
}



.mapboxgl-ctrl-bottom-left {
  display: none;
}
.mapboxgl-ctrl-bottom-right {
  display: none;
}

.mapboxgl-canvas-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

// FORMS
.input-row {
  box-sizing: border-box;
  margin: 10px auto;

  input {
    width: 100%;
    padding: 10px;
    font-size: 1.2em;
    box-sizing: border-box;
  }

  select {
    width: 100%;
    padding: 10px;
  }
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 15px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
}


.info-window {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: black;
  border: 1px solid $red;
  max-width: 500px;
  padding: 30px;
}

.info-window--sm {
  max-width: 220px;
}

.info-window__close {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}
.info-window__close:hover {
  color: $red;
}

#manipulator {
  height: 70px;
}




.autocomplete {
  position: relative;
  border: 2px solid white;
}

.autocomplete__results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  color: black;
  overflow-y: scroll;
  max-height: 200px;
  display: none;
}

.autocomplete-result {
  padding: 15px;
  border-bottom: 1px solid grey;
}
.autocomplete-result:hover {
  background-color: black;
  color: white;
  cursor: pointer;
}


.autocomplete[data-active='true'] {
  .autocomplete__results {
    display: block;
  }
}

.grecaptcha-badge {
	display: none !important;
}

.video3D {
  position: absolute;
  top: -999vh;
  left: -999vw;
  visibility: hidden;
}

@media (max-height: 560px) {
  .wa-service-btn-spotify,
  .wa-service-btn-apple-music,
  .wa-service-btn-tidal {
    font-size: 0.6em !important;
  }
}


.btn--primary {
  background-color: $yellow;
  border: 1px solid $blue;
  color: $blue !important;
  font-family: 'druk-wide-medium', sans-serif !important;
  box-shadow: -2px 10px 0px 0px $blue;
  color: white;
}

.dreamCanteen {
  font-family: 'druk-wide-medium', sans-serif;

  .btn--primary {
    background-color: $yellow;
    border: 1px solid $blue;
    color: $blue !important;
    font-family: 'druk-wide-medium', sans-serif;
    box-shadow: -2px 10px 0px 0px $blue;
    color: white;
  }


  .info-window {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: black;
    border: 1px solid $yellow;
    max-width: 500px;
    padding: 30px;
  }
  
  .info-window--sm {
    max-width: 220px;
  }
  
  .info-window__close {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
  }
  .info-window__close:hover {
    color: $yellow;
  }
}

.playlist-save__wrapper {
  position: fixed;
  bottom: 140px;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 380px;
  text-align: center;
  display: none;
  pointer-events: none;
  font-family: 'druk-wide-medium', sans-serif;

  p{
    color: white;
  }
}
.playlist-save__wrapper[data-active='true'] {
  display: block;
  pointer-events: all;
}

@media (max-height: 380px) {
  .playlist-save__wrapper {
    width: 98%;
  }
}