$red: #ed1c24;
$yellow: #ffec89;
$blue: #37489c;

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0,0,0,0.8);
}

.close {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
  background-color: black;
  width: 100px;
  padding: 10px;
}
.close:hover {
  background-color: $red;
}

.closeDc {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;
  background-color: black;
  width: 100px;
  padding: 10px;
}
.closeDc:hover {
  color: $blue;
  background-color: $yellow;
}

.controls {
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}