$red: #ed1c24;
$yellow: #ffec89;
@font-face {
  font-family: 'nightlight-condensed';
  src: url('../../../src/fonts/NightLightCompressed.otf');
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: black;
  background-image: url('../../images/rhcp-exterior_render-2.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

@media (max-width: 800px) {
  .wrapper {
    background-image: url('../../images/rhcp-exterior_render-2-mobile.jpg');
  } 
}


.wrapperOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
}

.content {
  position: relative;
  max-width: 700px;
}
@media (max-width: 800px) {
  .content {
    max-width: 95%;
  }
}


p {
  font-size: 1.2em;
}
@media (max-width: 800px) {
  p {
    font-size: 1.1em;
  }
}




.emailFormWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0,0,0,0.8);
}

.emailForm {
  max-width: 500px;
}

.enterWithEmail {
  margin: 20px auto;
}
@media (max-height: 560px) {
  .enterWithEmail {
    margin: 5px auto;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 450px;
  margin: 0 auto;

  p {
    margin-bottom: 8px;
    width: 100%;
    font-size: 1.3em;
  }

  button {
    margin-top: 20px;
    font-size: 1.5em;
  }
}

.loadingHr {
  width: 240px;
}

.loadingLogo {
  height: 130px;
  margin-bottom: 18px;
}

.welcomeTitle {
  font-family: 'nightlight-condensed', sans-serif;
  color: $red;
  font-size: 6em;
  font-weight: 400;
  border-bottom: 4px solid $red;
  margin: 0;
}
@media (max-width: 800px) {
  .welcomeTitle {
    font-size: 4.4em;
  }
}

.welcomeHeader {
  margin: 0;
}

@media (max-height: 680px) {
  .loadingLogo {
    height: 100px;
  }
  .welcomeTitle {
    font-size: 5em;
  }

  .loading {
    button {
      font-size: 0.9em;
      padding: 12px;
      margin-top: 6px;
    }
    p {
      font-size: 1.2em;
    }
  }
}

@media (max-height: 560px) {
  .loadingLogo {
    height: 80px;
  }

  .welcomeHeader {
    font-size: 1.3em;
  }

  .welcomeTitle {
    font-size: 4em;
  }

  .loading {
    button {
      font-size: 0.8em;
      padding: 10px;
      margin-top: 6px;
    }
    p {
      font-size: 0.9em;
    }
  }
}


.loadingBar {
  position: relative;
  margin-top: 30px;
  border: 2px solid $red;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  user-select: none;
}

.loadingBar::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: $red;
  animation: loading 4.5s ease forwards;
}

@keyframes loading {
  0% {
    width: 0%;
  }
  100% {
    width: 95%;
  }
}

@media (max-height: 560px) {
  .loadingBar {
    margin-top: 10px;
    padding: 8px;
  }
  .loadingText {
    font-size: 0.8em;
  }
}

.loadingText {
  position: relative;
}

.loadingConditional[data-status="loading"] {
  position: relative;
}
.loadingConditional[data-status="enter"] {
  display: none;
}

.enterContent {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  z-index: -999;
}

.enterContent[data-status="enter"] {
  position: relative;
  z-index: 0;
  visibility: visible;
  opacity: 1;
}

@media (max-height: 560px) {
  .enterContent {
    button {
      margin-top: 0;
      font-size: 0.8em;
    }
  }
}

.emailClose {
  cursor: pointer;
}
.emailClose:hover {
  color: $yellow;
}