$red: #ed1c24;
$blue: #37489c;

.wrapper {
  position: fixed;
  top: 0;
  right: -50%;
  width: 50%;
  height: 100%;
  background-color: black;
  transition: right 500ms ease;
  z-index: 1;
  display: flex;
  flex-direction: column;
}
.wrapper[data-active="false"] {
  right: -50%;
  transition: right 500ms ease;
}
.wrapper[data-active="true"] {
  right: 0;
  transition: right 500ms ease;
}

.wrapper[data-special="true"] {
  background-image: url('../../../src/images/metalic-overlay.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: $blue;
}

@media (max-width: 800px) {
  .wrapper {
    width: 90%;
  }
  .wrapper[data-active="false"] {
    right: -90%;
  }
}

.header {
  width: 100%;
  height: 30%;
  background-color: grey;
}

.close {
  margin-bottom: 40px;
  cursor: pointer;
}

.content {
  flex: 1 1 auto;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
@media (max-width: 800px) {
  .content {
    padding: 20px;
  }
}

.scrollWrap {
  height: 100%;
  overflow-y: scroll;
}

.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  // height: 40%;
  // flex: 0 0 auto;
}

.addressWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  // height: 20%;
  font-size: 1.4em;
  margin-bottom: 50px;
}

.address {
  width: 65%;
}

.directions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  svg {
    height: 1.4em;
    margin-bottom: 8px;
    fill: white;
  }
}

.section[data-role="vinyl"] {
  // height: 50%;
}

.vinylWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .vinylImgWrapper {
    width: 28%;
    // min-height: 80px;
    margin: 10px;

    img {
      width: 100%;
    }
  }
  @media (max-width: 800px) {
    .vinylImgWrapper {
      width: 46%;
      // height: 90px;
      margin: 4px;
    }
  }
}

.vinylDisclaimer {
  margin: 20px 0;
}

.promoDisclaimer {
  margin: 20px 0;
  color: $blue;
}

.section[data-role="pin-feed"] {
  // height: 50%;
}

.pinFeed {
  flex: 1 1 auto;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.pinWrapper {
  width: 30%;
  flex: 0 0 30%;
  margin: 0 8px 8px 0;
}

.pinAspect {
  position: relative;
  height: 0;
  padding-top: 100%;
}

.pin {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}