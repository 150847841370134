$red: #ed1c24;
$yellow: #ffec89;

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0,0,0,0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content {
  max-width: 500px;
}

.form {
  visibility: visible;
  z-index: auto;
}
.form[data-status="true"] {
  visibility: hidden;
  z-index: -99;
}
.formStatus {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 80px auto;
  visibility: hidden;
  z-index: -99;
}
.formStatus[data-status="true"] {
  visibility: visible;
  z-index: auto;
}

.close {
  font-size: 1.2em;
  cursor: pointer;
}
.close:hover {
  color: $red;
}

.closeDc {
  font-size: 1.2em;
  cursor: pointer;
}
.closeDc:hover {
  color: $yellow;
}

.pinConfirmation {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  hr {
    width: 200px;
  }
  p {
    max-width: 310px;
  }
}

