$red: #ed1c24;

@font-face {
  font-family: 'nightlife-square';
  src: url('./../../fonts/NightLightSquare.ttf');
}

.wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px 30px 30px 30px;
  box-sizing: border-box;
  background-color: rgba(0,0,0,0.8);
  font-family: 'nightlife-square', sans-serif;
  z-index: 9999999999999;

  button {
    font-family: 'nightlife-square', sans-serif;
  }

  a {
    color: $red;
    text-decoration: underline;
  }
}



