.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 800px;
  background: black;
  color: white;
  text-align: center;
}
@media (max-width: 800px) {
  .loading {
    max-width: 90%;
  }
}

.containerWrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 800px;
  // background: white;
}
@media (max-width: 800px) {
  .containerWrapper {
    max-width: 90%;
  }
}

.videoContainer {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
.videoContainer iframe, .video-container object, .video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.close {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  color: white;
  cursor: pointer;
  font-size: 1.4em;
}