$text-primary: white;



.title {
  position: absolute;
  top: 20px;
  left: 40px;
  font-size: 1.4em;
  padding: 0;
  height: 2em;
  width: 2em;
  position: relative;
  z-index: 1;

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 0;
  }
}

.navTrigger {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 30px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}

.iconCenter {
  width: 100%;
  height: 2px;
  background-color: $text-primary;
  opacity: 1;
  transition: all 300ms ease;
}

.iconAnimate::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  transform-origin: top left;
  background-color: $text-primary;
  transition: all 300ms ease;
}
.iconAnimate::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  transform-origin: bottom left;
  background-color: $text-primary;
  transition: all 300ms ease;
}

.menuOpen .iconCenter {
  opacity: 0;
  transition: all 300ms ease;
}
.menuOpen .iconAnimate::before {
  top: -3px;
  transform: rotate(45deg) ;
  background-color: $text-primary;
  transition: transform 300ms ease, top 300ms ease;
}
.menuOpen .iconAnimate::after {
  bottom: -3px;
  transform: rotate(-45deg);
  background-color: $text-primary;
  transition: transform 300ms ease, bottom 300ms ease;
}

.presaveTrigger {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}